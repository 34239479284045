import { useState, useEffect } from 'react';

import {
  ArrowBack,
  Login,
  PersonAdd,
  HomeOutlined,
  InfoOutlined,
  HighlightOutlined,
} from '@mui/icons-material';
import {
  List, ListItemAvatar, Divider, ListItem, ListItemText,
  Avatar, Drawer, IconButton, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import UserMenu, { AccountMenuItem } from '../UserMenu/index.client';
import { baseUrl, defaultProfilePic } from '@/config';
import { useUserContext } from '@/contexts/User/UserContext';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const MobileMenu = ({ showMobileMenu, closeMobileMenu, unreadMessages }) => {
  const [backUrl, setBackUrl] = useState('');
  const { userState } = useUserContext();
  const { account } = userState;
  const { pathname } = useRouter();

  useEffect(() => {
    if (pathname !== '/') {
      setBackUrl(`?backUrl=${encodeURIComponent(window.location.href).replace(/\./g, '%2E')}`);
    }
  }, [pathname]);

  return (
    <Drawer
      anchor="left"
      open={showMobileMenu}
      sx={{ maxWidth: '200px', display: { xs: 'block', lg: 'none' } }}
      ModalProps={{
        onClose: closeMobileMenu,
      }}
    >
      <DrawerHeader>
        <IconButton onClick={closeMobileMenu}>
          <ArrowBack fontSize="large" sx={{ color: '#fff' }} />
        </IconButton>
      </DrawerHeader>

      {account && (
        <ListItem>
          <ListItemAvatar>
            <a href={`${baseUrl}/fiok`} target="_self">
              <Avatar
                variant="rounded"
                alt={account?.name}
                src={account?.avatar_image?.url || defaultProfilePic}
              />
            </a>
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={(
              <Typography variant="h4" color="primary">{account?.name || 'Felhasználó'}</Typography>
          )}
          />
        </ListItem>
      )}

      <Divider sx={{ borderColor: '#fff', margin: '5px 15px' }} />
      <List>
        {account ? (
          <UserMenu component="list" unreadMessages={unreadMessages} />
        ) : (
          <>
            <AccountMenuItem
              component="list"
              id="modal-login-open-mobile"
              href={`${baseUrl}/belepes${backUrl}`}
              icon={Login}
              label="Belépés"
            />
            <AccountMenuItem
              component="list"
              href={`${baseUrl}/regisztracio${backUrl}`}
              icon={PersonAdd}
              label="Regisztráció"
            />
          </>
        )}
      </List>
      <Divider sx={{ borderColor: '#fff', margin: '0 15px' }} />
      <List>
        <AccountMenuItem
          component="list"
          href="https://docs.jofogas.hu/haztol-hazig/"
          icon={HomeOutlined}
          label="Háztól-házig szolgáltatás"
        />
        <AccountMenuItem
          component="list"
          href="https://docs.jofogas.hu/"
          icon={HighlightOutlined}
          label="Kiemelés"
        />
        <AccountMenuItem
          component="list"
          href={`${baseUrl}/rolunk`}
          icon={InfoOutlined}
          label="Rólunk"
        />
      </List>
    </Drawer>
  );
};

MobileMenu.propTypes = {
  showMobileMenu: PropTypes.bool.isRequired,
  closeMobileMenu: PropTypes.func.isRequired,
};

export default MobileMenu;
