import React from 'react';

import {
  MessageOutlined,
  InterestsOutlined,
  FavoriteBorder,
  LogoutOutlined,
  SettingsOutlined,
  PeopleOutlined,
  Storage,
  LocalShippingOutlined,
  HelpOutline,
} from '@mui/icons-material';
import {
  Badge, ListItemButton, ListItemIcon, ListItemText, MenuItem, SvgIcon,
} from '@mui/material';
import { useRouter } from 'next/router';

import SavedSearch from '@/components/icons/saved-search';
import { baseUrl } from '@/config';
import { logout } from '@/contexts/User/helpers';
import { useUserContext } from '@/contexts/User/UserContext';
import utagData from '@/helpers/utagData';

const hoverStyle = {
  ':hover': {
    backgroundColor: '#44607c',
  },
};

export const AccountMenuItem = React.forwardRef(({
  component, badge, icon, label, ...args
}, ref) => {
  if (component === 'menu') {
    return (
      <MenuItem
        component="a"
        {...args}
        sx={{ color: 'secondary.main' }}
        ref={ref}
      >
        <ListItemIcon sx={{ color: 'secondary.main' }}>
          {badge ? (
            <Badge color="secondary" variant="dot">
              <SvgIcon component={icon} color="secondary" />
            </Badge>
          ) : (
            <SvgIcon component={icon} color="secondary" />
          )}
        </ListItemIcon>
        <ListItemText sx={{ color: 'secondary.main', ml: 2 }} primary={label} />
      </MenuItem>
    );
  }
  return (
    <ListItemButton component="a" {...args} sx={hoverStyle} ref={ref}>
      <ListItemIcon sx={{ color: '#fff', paddingLeft: '10px' }}>
        {badge ? (
          <Badge color="primary" variant="dot">
            <SvgIcon component={icon} color="inherit" />
          </Badge>
        ) : (
          <SvgIcon component={icon} color="inherit" />
        )}
      </ListItemIcon>
      <ListItemText sx={{ color: '#fff' }} primary={label} />
    </ListItemButton>
  );
});

const UserMenu = ({ component, unreadMessages }) => {
  const { userState, setUserState } = useUserContext();
  const { token, account } = userState;
  const router = useRouter();

  const onLogout = () => logout(token).then((res) => {
    setUserState(res);
    utagData.setAccountVariables(null);
    router.reload();
  });

  return (
    <>
      <AccountMenuItem
        component={component}
        icon={MessageOutlined}
        badge={unreadMessages > 0}
        label="Üzenetek"
        href={`${baseUrl}/fiok/postalada`}
      />
      <AccountMenuItem
        component={component}
        icon={InterestsOutlined}
        label="Hirdetéseim"
        href={`${baseUrl}/fiok/hirdeteseim`}
      />
      {(account?.account_level === '5') ? (
        <>
          <AccountMenuItem
            component={component}
            icon={PeopleOutlined}
            label="Referensek"
            href={`${baseUrl}/fiok/referensek`}
          />
          <AccountMenuItem
            component={component}
            icon={Storage}
            label="Kreditkezelés"
            href={`${baseUrl}/fiok/kreditkezeles`}
          />
        </>
      ) : (
        <AccountMenuItem
          component={component}
          icon={LocalShippingOutlined}
          label="Rendelések"
          href={`${baseUrl}/fiok/haztol-hazig/eladok`}
        />
      )}
      <AccountMenuItem
        component={component}
        icon={SavedSearch}
        label="Mentett keresések"
        href={`${baseUrl}/fiok/mentett_keresesek`}
      />
      <AccountMenuItem
        component={component}
        icon={FavoriteBorder}
        label="Kedvenc hirdetések"
        href={`${baseUrl}/fiok/kedvencek`}
      />
      <AccountMenuItem
        component={component}
        icon={SettingsOutlined}
        label="Beállítások"
        href={`${baseUrl}/fiok`}
      />
      {(account?.account_level === '5' || account?.account_level === '6') && (
      <AccountMenuItem
        component={component}
        href="https://docs.jofogas.hu/irodai_fiok/"
        icon={HelpOutline}
        label="Fiók ismertető"
      />
      )}
      <AccountMenuItem
        component={component}
        id="link-logout"
        onClick={onLogout}
        icon={LogoutOutlined}
        label="Kilépés"
      />
    </>
  );
};

export default UserMenu;
